import PublicTransport from '../util/billing_items/public_transport'

up.compiler('[billing-item-public-transport]', (element) => {
  const form = element.closest('form')

  const publicTransportBillingItem = new PublicTransport(element)

  form.addEventListener('submit', function(event) {
    if (!publicTransportBillingItem.isValid()) {
      event.preventDefault()
    }
  })
})
