import Car20 from '../util/billing_items/car20'

up.compiler('[billing-item-car-20]', (element) => {
  const form = element.closest('form')
  const carBillingItem = new Car20(element)

  form.addEventListener('submit', function(event) {
    if (!carBillingItem.isValid()) {
      event.preventDefault()
    }
  })
})
