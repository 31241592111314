import autosize from 'autosize'
import validateBillingItemField from '../../shared/util/validate_billing_item_field'
import { formatMoneyAmount } from './format'

export default class BillingItem {
  constructor(element) {
    this.element = element
    this.fields = {}
    this.fields.amount = this.findField('amount')
    this.fields.upperLimit = this.fields.amount?.dataset.upperLimit
    this.fields.description = this.findField('description')
    this.fields.reason = this.findField('reason')
    this.fields.destroy = element.querySelector('[name*="_destroy"][type="checkbox"]')

    this.userInputFields = []
    if (this.fields.reason) {
      this.userInputFields.push(this.fields.reason)
    }

    this.element.billingItem = this
  }

  findField(fieldName) {
    return this.element.querySelector(`[name*="${fieldName}"]`)
  }

  updateDescription(text) {
    const field = this.fields.description
    field.value = text
    window.as = autosize
    autosize.update(field)
  }

  getUserInputFields() {
    return this.userInputFields
  }

  updateAmount() {
    this.setAmount()
    this.ensureUpperLimit()
    up.emit('app:total-billing-items-amount:update')
  }

  setAmount() {
    this.fields.amount.value = this.calculateAmount().toFixed(2)
  }

  calculateAmount() {
    return parseFloat(this.fields.amount.value)
  }

  ensureUpperLimit() {
    const upperLimit = this.getUpperLimit()

    if (upperLimit && this.getAmount() > upperLimit) {
      this.announceUpperLimitAdjustment()
      this.fields.amount.value = upperLimit
    }
  }

  announceUpperLimitAdjustment() {
    this.upperLimitAlert()
  }

  generateDescription() {
    // override in subclasses
  }

  registerEventListeners() {
    this.getUserInputFields().forEach((input) => {
      input?.addEventListener('change', function() {
        this.generateDescription()
      }.bind(this))
    })

    this.registerAmountListener()
  }

  registerAmountListener() {
    if (this.getUpperLimit() && !this.fields.amount.readOnly) {
      this.fields.amount.addEventListener('blur', this.updateAmount.bind(this))
    }
  }

  isValid() {
    if (this.isDestroyed()) {
      this.userInputFields.forEach((input) => {
        this.markAsValid(input)
      })
      return true
    }

    let valid = true

    this.userInputFields.forEach((field) => {
      if (!field) return // tests my have null values here

      const fieldValid = validateBillingItemField(field)
      valid &&= fieldValid
    })

    if (this.getAmount() <= 0) {
      this.markAsInvalid(this.fields.amount)
      valid = false
    }
    return valid
  }

  getAmount() {
    if (this.isDestroyed()) {
      return 0
    } else {
      return parseFloat(this.fields.amount.value) || 0
    }
  }

  getUpperLimit() {
    if (this.isDestroyed()) {
      return null
    } else {
      return this.fields.upperLimit ? parseFloat(this.fields.upperLimit).toFixed(2) : null
    }
  }

  isDestroyed() {
    return this.fields.destroy && this.fields.destroy.checked
  }

  markAsInvalid(input) {
    input.classList.add('is-invalid')
  }

  markAsValid(input) {
    input.classList.remove('is-invalid')
  }

  upperLimitAlert() {
    alert(`Möglicher Maximalbetrag ist ${formatMoneyAmount(this.getUpperLimit())}. Der eingegebene Betrag wird entsprechend angepasst.`)
  }
}
