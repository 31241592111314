import BillingItem from '../billing_item'
import fadeOut from '../fade_out'
import { formatDate, formatMoneyAmount } from '../format'
import validateBillingItemField from '../validate_billing_item_field'

export default class DailyAllowancesPart extends BillingItem {
  constructor(element, parent) {
    super(element)
    this.parent = parent
    this.allowancePerDay = this.parent.allowancePerDay

    this.fields.date = this.findField('date')
    this.fields.type = this.findField('type')
    this.fields.breakfast = this.findField('breakfast')
    this.fields.lunch = this.findField('lunch')
    this.fields.dinner = this.findField('dinner')

    this.userInputFields.push(this.fields.date, this.fields.type, this.fields.breakfast, this.fields.lunch, this.fields.dinner)

    this.registerEventListeners()
    this.setAmount()
  }

  registerEventListeners() {
    this.getUserInputFields().forEach((input) => {
      input?.addEventListener('change', function() {
        this.setAmount()
        this.parent.refreshAll()
      }.bind(this))
    })

    this.fields.destroy.addEventListener('input', async ({ target }) => {
      target.checked &&= confirm(`${target.labels[0]?.title || 'Löschen'}?`)
      if (!target.checked) return

      await fadeOut(this.element)
      this.element.hidden = true
      this.parent.refreshAll()
    })
  }

  calculateAmount() {
    if (this.isDestroyed()) return 0

    let amount = this.getMaxAmount()
    if (this.fields.breakfast.checked) { amount -= this.allowancePerDay / 5 }
    if (this.fields.lunch.checked) { amount -= this.allowancePerDay * 2 / 5 }
    if (this.fields.dinner.checked) { amount -= this.allowancePerDay * 2 / 5 }

    if (amount < 0) { amount = 0 }

    return amount
  }

  generateDescription() {
    const date = formatDate(this.fields.date.value) || '(unbekannter Tag)'
    const type = this.fields.type.selectedOptions[0].textContent || '(unbekannt)'

    return `${date} ${type}` +
      (this.fields.breakfast.checked ? ` - Frühstück (${formatMoneyAmount(this.allowancePerDay / 5)})` : '') +
      (this.fields.lunch.checked ? ` - Mittagessen (${formatMoneyAmount(this.allowancePerDay * 2 / 5)})` : '') +
      (this.fields.dinner.checked ? ` - Abendessen (${formatMoneyAmount(this.allowancePerDay * 2 / 5)})` : '') +
      ` = ${formatMoneyAmount(this.calculateAmount())}`
  }

  getMaxAmount() {
    switch (this.fields.type.value) {
      case 'full_day':
        return this.allowancePerDay
      case 'arrival':
      case 'departure':
      case 'single_day':
      case 'single_night':
        return this.allowancePerDay / 2
      case '':
        return 0
      default:
        throw new Error(`Unknown daily allowance type: ${this.fields.type.value}`)
    }
  }

  isValid() {
    super.isValid()
    if (this.isDestroyed()) {
      this.userInputFields.forEach((input) => {
        this.markAsValid(input)
      })
      return true
    }

    let valid = true

    this.userInputFields.forEach((field) => {
      if (!field) return // tests my have null values here

      const fieldValid = validateBillingItemField(field)
      valid &&= fieldValid
    })

    return valid
  }
}
