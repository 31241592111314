import environment from '../util/environment'

up.layer.config.modal.dismissable = 'button key' // do not allow closing modals by clicking the backdrop

up.link.config.followSelectors.push('a[href]')
up.link.config.instantSelectors.push('a[href]')
up.link.config.noInstantSelectors.push('.btn:not(.btn-link), [no-instant]')

up.fragment.config.runScripts = false

up.form.config.submitSelectors.push('form')

up.history.config.updateMetaTags = true

up.motion.config.enabled = !environment.isTest

up.network.config.progressBar = true

up.on('up:network:late', () => { document.body.classList.add('-loading') })
up.on('up:network:recover', () => { document.body.classList.remove('-loading') })
