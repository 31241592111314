import BillingItemWithReceipts from '../billing_item_with_receipts'

export default class PublicTransport extends BillingItemWithReceipts {
  constructor(element) {
    super(element)
    this.fields.origin = this.findField('origin')
    this.fields.destination = this.findField('destination')

    this.userInputFields.push(this.fields.origin, this.fields.destination, this.fields.amount)
    this.registerEventListeners()
  }

  generateDescription() {
    const origin = this.fields.origin.value || '(unbekannt)'
    const destination = this.fields.destination.value || '(unbekannt)'
    this.updateDescription(`ÖPNV von ${origin} nach ${destination}.`)
  }
}
