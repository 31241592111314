import BillingItem from '../billing_item'
import Address from '../address'

export default class Car extends BillingItem {
  constructor(element) {
    super(element)
    this.fields.originStreet = this.findField('street_and_number_1')
    this.fields.originZip = this.findField('zip_1')
    this.fields.originCity = this.findField('city_1')
    this.fields.destinationStreet = this.findField('street_and_number_2')
    this.fields.destinationZip = this.findField('zip_2')
    this.fields.destinationCity = this.findField('city_2')
    this.fields.distance = this.findField('distance')
    this.originAddress = new Address(this.fields.originStreet, this.fields.originZip, this.fields.originCity)
    this.destinationAddress = new Address(this.fields.destinationStreet, this.fields.destinationZip, this.fields.destinationCity)
    this.originDefaultAddress = element.querySelector('.default-address-1')
    this.destinationDefaultAddress = element.querySelector('.default-address-2')

    this.userInputFields.push(this.fields.originStreet, this.fields.destinationStreet, this.fields.originZip, this.fields.destinationZip, this.fields.originCity, this.fields.destinationCity, this.fields.distance)
    this.registerEventListeners()
    this.updateDefaultAddresses()
  }

  registerAmountListener() {
    this.fields.distance.addEventListener('focusout', this.updateAmount.bind(this))
  }

  generateDescription() {
    const description = `
      Es wurden ${this.distance()} Kilometer von ${this.originAddress.toString()} nach ${this.destinationAddress.toString()} gefahren.
      Link zu Routenplaner: ${this.tourLink()}
    `.replace(/^\s+|\s+$/gm, '')

    this.updateDescription(description)
    this.updateDefaultAddresses()
  }

  updateDefaultAddresses() {
    this.originDefaultAddress.innerText = this.originAddress.toString('(Adresse der abrechnenden Person)')
    this.destinationDefaultAddress.innerText = this.destinationAddress.toString('(Adresse der Veranstaltung)')
  }

  distance() {
    return this.fields.distance.value || '(unbekannt)'
  }

  isValid() {
    let valid = super.isValid()
    if (!this.isDestroyed() && this.fields.distance.value <= 0) {
      this.markAsInvalid(this.fields.distance)
      valid = false
    }
    return valid
  }

  tourLink() {
    const addresses = [this.originAddress.toString(), this.destinationAddress.toString()]

    const tourParams = addresses.map(address => encodeURIComponent(address).replace(/%20/g, '+')).join('/')

    return 'https://www.google.de/maps/dir/' + tourParams
  }
}
